
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { FormResultDto, UserDto } from '../../../../api/types';

@Component({})
export default class EvaluationTemplate extends Vue {
  @Prop() student!: UserDto;
  @Prop() grade!: string;
  @Prop() form!: string;
  @Prop() data?: FormResultDto;
}

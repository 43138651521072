
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class StudentDashboardHeadline extends Vue {
  @Prop() grade!: string;
  @Prop({ default: false }) isTherapist?: boolean;
}

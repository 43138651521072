
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { UserDto } from '../../../../api/types';

@Component({})
export default class ClassListTemplate extends Vue {
  @Prop() students!: UserDto[];
  @Prop() grade!: string;

  get leo(): string {
    return require('@/assets/images/lion_small.png');
  }

  get url(): string {
    return 'lesikus.alphaport.at';
  }
}

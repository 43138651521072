
import { Role, UserDto } from '@/api/types';
import SchoolModule from '@/store/modules/SchoolModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import UserModule from '@/store/modules/UserModule';
import { getDefaultPassword, getNameAbbr } from '@/utils/common';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import { minLength, required } from 'vuelidate/lib/validators';
import { getModule } from 'vuex-module-decorators';

// const isUnique = (value: any, props: any) => {
//   return !props.ids.includes(value);
// };

@Component({
  mixins: [validationMixin],
  validations: {
    firstname: { required, minLength: minLength(3) },
    lastname: { required, minLength: minLength(3) },
  },
  components: {},
})
export default class AddStudentAccount extends Vue {
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);

  @Prop() dialog!: boolean;
  @Prop() grade!: string;
  @Prop() students!: UserDto[];
  @Prop() ids!: number[];

  MAX_STUDENTS = 30;
  firstname = '';
  lastname = '';
  email = '';
  cStudentId: number | null = null;
  loading = false;

  get numberOfStudents(): number {
    return this.schoolModule.myStudents.length;
  }

  get schoolSuffix(): string {
    return `_${this.schoolId}`;
  }

  get schoolId(): string {
    return this.schoolModule.schoolId!.toString();
  }

  get isTherapist(): boolean {
    return this.userModule.currentUser.role === Role.therapist;
  }

  @Emit()
  closeAddDialog(): void {
    this.clear();
  }

  async validate(): Promise<void> {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.loading = true;
      try {
        if (this.numberOfStudents === this.MAX_STUDENTS) {
          throw new Error('reached student maximum');
        }
        const DEFAULT_PASSWORD = getDefaultPassword(
          this.firstname,
          this.lastname,
        );
        const nameAbbr = getNameAbbr(this.firstname, this.lastname);
        const studentUsername = `${nameAbbr}${this.grade}${this.schoolSuffix}`;

        await this.schoolModule.addUser({
          username: studentUsername,
          firstname: this.firstname,
          lastname: this.lastname,
          //INFO strange bug: is number but backend received string => extra casting
          studentId: parseInt((this.numberOfStudents + 1).toString()),
          grade: this.grade,
          schoolId: parseInt(this.schoolId.toString()),
          role: Role.student,
          password: DEFAULT_PASSWORD,
          studentPassword: DEFAULT_PASSWORD,
        });
        this.snackbarModule.showMessage({
          message: this.$i18n.t('snackbar.students.created.success').toString(),
          isSuccess: true,
        });
        this.addedStudent();
        this.clear();
      } catch (error: any) {
        if (error?.message === 'reached student maximum') {
          this.snackbarModule.showMessage({
            message: this.$i18n.t('snackbar.students.created.max').toString(),
            isSuccess: false,
          });
        } else {
          this.snackbarModule.showMessage({
            message: this.$i18n.t('snackbar.students.created.error').toString(),
            isSuccess: false,
          });
        }
      }
      this.loading = false;
      this.closeAddDialog();
    }
  }

  @Emit()
  addedStudent() {
    console.log();
  }

  clear(): void {
    this.$v.$reset();
    this.firstname = '';
    this.lastname = '';
    this.email = '';
    this.cStudentId = null;
  }

  get numberErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.studentId.$dirty) return errors;
    !this.$v.studentId.numeric && errors.push('Nur Zahlen erlaubt!');
    !this.$v.studentId.minValue && errors.push('Nur Zahlen > 0 erlaubt!');
    !this.$v.studentId.required && errors.push('Bitte ID angeben.');
    !this.$v.studentId.isUnique && errors.push('ID schon vergeben!');
    return errors;
  }

  get firstnameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.firstname.$dirty) return errors;
    !this.$v.firstname.minLength && errors.push('Mind. 3 Zeichen');
    !this.$v.firstname.required && errors.push('Bitte Vornamen angeben.');
    return errors;
  }
  get lastnameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.lastname.$dirty) return errors;
    !this.$v.lastname.minLength && errors.push('Mind. 3 Zeichen');
    !this.$v.lastname.required && errors.push('Bitte Nachnamen angeben.');
    return errors;
  }
}


import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class AddAccountButton extends Vue {
  @Prop()
  isTherapist!: boolean;

  @Emit()
  openAddDialog() {
    console.log('');
  }
}

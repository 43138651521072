
import SchoolModule from '@/store/modules/SchoolModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { validationMixin } from 'vuelidate';
import {
  minLength,
  minValue,
  numeric,
  required,
} from 'vuelidate/lib/validators';
import { getModule } from 'vuex-module-decorators';
import AdminSnackbar from '../../base/AdminSnackbar.vue';

import { Role } from '@/api/types';
import UserModule from '@/store/modules/UserModule';
import { getDefaultPassword, getNameAbbr } from '@/utils/common';
import { Emit, Prop } from 'vue-property-decorator';
import SnackbarModule from '@/store/modules/SnackbarModule';

@Component({
  mixins: [validationMixin],
  validations: {
    firstname: { required, minLength: minLength(3) },
    lastname: { required, minLength: minLength(3) },
    studentId: { required, numeric, minValue: minValue(1) },
    grade: { required },
  },
  components: { AdminSnackbar },
})
export default class AddPatientAccount extends Vue {
  @Prop() dialog!: boolean;

  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);

  firstname = '';
  lastname = '';
  email = '';
  grade = '';
  studentId: number | null = null;
  enableA = false;
  enableB = false;

  message = 'Schüler/in erfolgreich hinzugefügt';

  snackbar = false;
  isSuccess = false;
  loading = false;

  get text(): string {
    return this.isSuccess
      ? 'Schüler/in erfolgreich hinzugefügt.'
      : 'Leider ist etwas schiefgelaufen.';
  }

  get grades(): string[] {
    return this.schoolModule.classes;
  }

  get schoolSuffix(): string {
    return `_${this.schoolId}`;
  }

  get schoolId(): string {
    return this.schoolModule.schoolId!.toString();
  }

  get isTherapist(): boolean {
    return this.userModule.currentUser.role === Role.therapist;
  }

  get isParent(): boolean {
    return this.userModule.currentUser.role === Role.parent;
  }

  @Emit()
  closeAddDialog(): void {
    console.log();
  }

  async validate(): Promise<void> {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.loading = true;
      try {
        const DEFAULT_PASSWORD = getDefaultPassword(
          this.firstname,
          this.lastname,
        );
        const nameAbbr = getNameAbbr(this.firstname, this.lastname, true);
        const studentUsername = `${nameAbbr}_${this.schoolId}`;

        const payload = this.isParent
          ? {
              username: studentUsername,
              firstname: this.firstname,
              lastname: this.lastname,
              studentId: parseInt(this.studentId!.toString()),
              grade: this.grade,
              schoolId: parseInt(this.schoolId.toString()),
              role: Role.student,
              password: DEFAULT_PASSWORD,
              studentPassword: DEFAULT_PASSWORD,
              enableA: this.enableA,
              enableB: this.enableB,
            }
          : {
              username: studentUsername,
              firstname: this.firstname,
              lastname: this.lastname,
              //INFO strange bug: is number but backend received string => extra casting
              studentId: parseInt(this.studentId!.toString()),
              grade: this.grade,
              schoolId: parseInt(this.schoolId.toString()),
              role: Role.student,
              password: DEFAULT_PASSWORD,
              studentPassword: DEFAULT_PASSWORD,
            };

        await this.schoolModule.addUser(payload);
        this.snackbarModule.showMessage({
          message: this.isTherapist
            ? this.$i18n.t('snackbar.patients.created.success').toString()
            : this.$i18n.t('snackbar.students.created.success').toString(),
          isSuccess: true,
        });
        this.addedStudent();
        this.clear();
      } catch (error) {
        this.snackbarModule.showMessage({
          message: this.isTherapist
            ? this.$i18n.t('snackbar.patients.created.error').toString()
            : this.$i18n.t('snackbar.students.created.error').toString(),
          isSuccess: false,
        });
      } finally {
        this.loading = false;
      }
      this.closeAddDialog();
    }
  }

  @Emit()
  addedStudent() {
    console.log();
  }

  clear(): void {
    this.$v.$reset();
    this.firstname = '';
    this.lastname = '';
    this.email = '';
    this.studentId = null;
    this.grade = '';
    this.enableA = false;
    this.enableB = false;
  }

  get numberErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.studentId.$dirty) return errors;
    !this.$v.studentId.numeric && errors.push('Nur Zahlen erlaubt!');
    !this.$v.studentId.minValue && errors.push('Nur Zahlen > 0 erlaubt!');
    !this.$v.studentId.required && errors.push('Bitte ID angeben.');
    return errors;
  }

  get firstnameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.firstname.$dirty) return errors;
    !this.$v.firstname.minLength && errors.push('Mind. 3 Zeichen');
    !this.$v.firstname.required && errors.push('Bitte Vornamen angeben.');
    return errors;
  }
  get lastnameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.lastname.$dirty) return errors;
    !this.$v.lastname.minLength && errors.push('Mind. 3 Zeichen');
    !this.$v.lastname.required && errors.push('Bitte Nachnamen angeben.');
    return errors;
  }
  get gradeErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.grade.$dirty) return errors;
    !this.$v.grade.required && errors.push('Bitte eine Klasse auswählen.');
    return errors;
  }

  showSnackbar(message: string, isSuccess: boolean) {
    this.snackbar = true;
    this.isSuccess = isSuccess;
    this.message = message;
    setTimeout(async () => {
      this.snackbar = false;
    }, 2500);
  }
}


import ClassListTemplate from '../pdf-templates/ClassListTemplate.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import SchoolModule from '@/store/modules/SchoolModule';
import { getModule } from 'vuex-module-decorators';
import { Prop, Emit } from 'vue-property-decorator';
import SnackbarModule from '@/store/modules/SnackbarModule';
import { UserDto } from '@/api/types';

@Component({
  components: { ClassListTemplate },
})
export default class ClassLevelSelect extends Vue {
  @Prop({ default: false }) isSingle?: boolean;
  @Prop() studentId?: string;

  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);

  level: number | null = null;
  sLevel: number | null = this.student?.level ? +this.student.level : null;

  items = [
    { value: 2, text: this.isSingle ? '2' : '2.Schulstufe' },
    { value: 3, text: this.isSingle ? '3' : '3.Schulstufe' },
    { value: 4, text: this.isSingle ? '4' : '4.Schulstufe' },
    { value: 5, text: this.isSingle ? '5' : '5.Schulstufe' },
    { value: 6, text: this.isSingle ? '6' : '6.Schulstufe' },
  ];

  label = this.isSingle ? '' : this.$t('classLevel.level').toString();
  placeholder = this.isSingle ? '' : this.$t('classLevel.label').toString();

  loading = false;
  showSuccess = false;
  showError = false;

  get student(): UserDto | undefined {
    return this.schoolModule.myStudents?.find(
      (student) => student._id === this.studentId,
    );
  }

  get students(): UserDto[] {
    return this.schoolModule.myStudents;
  }

  get singleLevel(): number | null {
    return this.student?.level ? +this.student.level : null;
  }

  set singleLevel(level: number | null) {
    this.sLevel = level;
  }

  async updateLevel() {
    try {
      this.loading = true;
      if (this.sLevel) {
        // TODO how to change on error
        // throw new Error('No level selected');
        await this.schoolModule.updateUser({
          id: this.student!._id,
          updateUser: {
            level: this.sLevel!,
          },
        });
        this.showSuccess = true;
        setTimeout(() => {
          this.showSuccess = false;
        }, 1500);
      }
    } catch (error) {
      // TODO handle error on sinlge setting
      this.singleLevel = this.student?.level ? +this.student.level : null;
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 1500);
      this.snackbarModule.showMessage({
        message: this.$i18n.t('classLevel.single-error').toString(),
        isSuccess: false,
        time: 6000,
      });
    } finally {
      this.updatedLevel();
      this.loading = false;
    }
  }

  async setClassLevel() {
    try {
      this.loading = true;
      if (this.students?.length !== 0) {
        for (const stud of this.students!) {
          await this.schoolModule.updateUser({
            id: stud._id,
            updateUser: {
              level: this.level!,
            },
          });
        }
        this.snackbarModule.showMessage({
          message: this.$i18n.t('classLevel.success').toString(),
          isSuccess: true,
        });
      }
    } catch (error) {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('classLevel.error').toString(),
        isSuccess: false,
      });
    } finally {
      this.updatedLevel();
      this.loading = false;
    }
  }

  @Emit()
  updatedLevel() {
    console.log();
  }

  get levelErrors(): string[] {
    const required = this.$t('classLevel.required').toString();
    const errors: string[] = [];
    if (!this.$v.level.$dirty) return errors;
    !this.$v.level.required && errors.push(required);
    return errors;
  }
}

import { render, staticRenderFns } from "./ClassLevelSelect.vue?vue&type=template&id=615e1cdf&scoped=true&"
import script from "./ClassLevelSelect.vue?vue&type=script&lang=ts&"
export * from "./ClassLevelSelect.vue?vue&type=script&lang=ts&"
import style0 from "./ClassLevelSelect.vue?vue&type=style&index=0&id=615e1cdf&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "615e1cdf",
  null
  
)

export default component.exports